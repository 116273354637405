.fc-header-toolbar {
    margin-bottom: 0!important;
    background-color: #061f46;
    padding: 1rem;
    color: #e5e7eb;
}

.fc-button-primary{
    background-color: #e5e7eb !important;
    border-color: #e5e7eb !important;
    color: #061f46;
}

.fc-icon{
    color: #061f46;
}

.fc-toolbar-title{
    font-weight: bold;
}

.teste{
    border-width: 5px!important;
    border-color: #FFF!important;
    background-color: #f9fafb;
    color: #475467;
    padding: 6px!important;

}
.fc-col-header-cell{
    border: none !important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.fc-day-other{
    background-color: #fcfcfd!important;
}

.fc-daygrid-day-top{
    flex-direction: initial!important;
}
.fc .fc-daygrid-day.fc-day-today{
    background-color: #e3ecfb!important;
}
.fc-day{
    cursor: pointer;
}

.days-header{
    font-size: 22px;
}

.fc-daygrid-day-number{
    width: 100%;
}

.fc-h-event{
    background-color: inherit;
    border: none;
}

.fc-toolbar-title::first-letter {
    text-transform: uppercase;
}
