@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family:'inter';
    src:url("../components/fonts/Inter-VariableFont_slnt,wght.ttf");
}

body {
    margin: 0;
    font-family: -apple-system, 'inter', serif ;/*-apple-system, BlinkMacSystemFont, 'dkLongreach','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}